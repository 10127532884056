module.exports = {
    pcregister: {
        p1: 'Уже есть аккаунт?',
        dl: 'Войти',
        zc: 'Регистрация',
        p4: 'Счет',
        p5: 'Пожалуйста, введите номер счета',
        p6: 'Введите пароль',
        p7: 'Введите пароль подтверждения',
        p8: 'Введите код приглашения',
        p9: 'Я согласен.',
        p10: 'Пользовательское соглашение и политика конфиденциальности',
        p11: 'Почтовый ящик',
        p12: 'Введите адрес электронной почты',
        p13: 'Введите код подтверждения почтового ящика',
        p14: 'Отправить',
        p15: 'Год',
        p16: 'Месяц',
        p17: 'Боже!',
        p18: 'Час',
        p19: 'Минута.',
        p20: 'Секунд'
    },
    pclogin: {
        l1: 'Нет аккаунта?',
        l2: 'Регистрация',
        l3: 'Войти',
        l4: 'Введите имя пользователя',
        l5: 'Введите пароль',
        l6: 'Забыли пароль',
        l7: 'Войти'
    },
    pccontract: {
        ss: 'Рыночная цена',
        xj: 'Предельная цена',
        gg: 'Рычаг'
    },
    pcrapidly: {
        zf: 'Увеличение',
        dd: 'Заказ',
        bdsj: 'Местное время',
        ky: 'Доступно'
    },
    pchome: {
        global1: 'Ведущая в мире платформа для торговли цифровыми активами',
        global2: 'Она предоставляет безопасные и надежные услуги по торговле цифровыми активами и управлению активами миллионам пользователей в более чем 100 странах.',
        global3: 'Безопасный и надежный',
        global4: 'Пять лет опыта в сфере цифровых финансовых услуг. Сосредоточьтесь на распределенной архитектуре и системах против DDoS-атак.',
        global5: 'Глобальный экологический макет',
        global6: 'В нескольких странах были созданы центры локализованных торговых услуг для создания блокчейн-экосистемы, которая объединяет несколько форматов.',
        global7: 'Пользователь в первую очередь',
        global8: 'Создать механизм предварительной компенсации и создать фонд защиты инвесторов.',
        whever1: 'Многоплатформенные терминальные транзакции в любое время и в любом месте',
        whever2: 'Охват нескольких платформ iOS, Android и Windows, поддерживает полностью бизнес-функции.',
        register1: 'Зарегистрируйте один',
        register3: 'Глобальный счет начинает торговать',
        register2: 'Зарегистрируйтесь сейчас'
    },
    pcbottom: {
        box1: 'Ведущие в мире',
        box2: 'Одна из лучших команд',
        box3: 'Больше',
        box4: 'О нас',
        box5: 'Белая книга',
        box6: 'Услуги',
        box7: 'Скачать',
        box8: 'Часто задаваемые вопросы',
        box9: 'Обслуживание клиентов онлайн',
        box10: 'Положения',
        box11: 'Пользовательское соглашение',
        box12: 'Политика конфиденциальности',
        box13: 'Правовое заявление',
        box14: 'Контакты'
    },
    pcAbout_us: {
        t1: 'История',
        t2: 'Мы, 7 лет пути',
        t3: 'С момента своего рождения в 2015 году мы посвятили себя обслуживанию блокчейнов, технологиям, работающим полный рабочий день, службам строительства каналов.',
        t4: 'В 2014-2017 годах он пережил восходящий период «стабильного и растущего». Первая половина 2017-2018 годов-это сумасшедший период «покупки денег, чтобы разбогатеть». Со второй половины 2019 года до сегодняшней холодной зимы я приобрел настоящую способность. Бесчисленные успешные партнеры позволяют нам иметь громкую репутацию в отрасли. В 2022 году у нас будет новая платформа V3.0 и новая акционерная компания BitGoldEX, которая работает в более чем 100 странах мира.',
        t5: 'Кого мы ищем?',
        t6: 'От основания до сегодняшнего дня',
        t7: 'Я искал поставщиков услуг, которые испытывают трудности и имеют сходные вкусы, в течение нескольких лет успешно решить проблемы для десятков поставщиков услуг.',
        t8: 'В рациональном сегодня, если вы хотите найти хорошего помощника и партнера, который может работать вместе, чтобы добиться дальнейшего прогресса-возможно, вы можете поговорить с нами.',
        t9: 'Пожалуйста, поверьте: человек, который вы ищете, на самом деле ищет вас.',
        t10: 'Услуги',
        t11: 'Нет продавца, есть бизнес.',
        t12: 'Друзья представляют вам бизнес, это значит, что вы в порядке. Пратники знакомят вас с бизнесом, что показывает, что у вас хорошая репутация. Когда клиенты знакомят вас с бизнесом, это доказывает, что вы заслуживаете доверия.',
        t13: 'Дух',
        t14: 'Мы кирпичи.',
        t15: 'Разные клиенты имеют разные требования. Разные продукты имеют разные характеристики. Разные сроки, разные цели. Смысл нашего существования состоит в том, чтобы адаптироваться к концепциям, системам и требованиям различных поставщиков услуг и решать различные проблемы.',
        t16: 'Мы готовы сделать вдумчивенный и полезный кирпич, и мы идем туда, где это необходимо.'
    },
    pcproblem: {
        p1: 'Часто задаваемые вопросы',
        p2: 'Почему меняется количество конвертируемых активов?',
        p3: 'Эквивалент в активе-это стоимость текущей цифровой валюты, преобразованной в USDT, которая изменяется из-за колебаний цены цифрового актива. Количество ваших цифровых активов не изменилось.',
        p4: 'Платформа торговли забыла метод обработки паролей средств',
        p5: 'Если вы забыли пароль торгового фонда платформы, перейдите в «Мои настройки-нажмите, чтобы установить пароль фонда» или обратитесь в службу поддержки для сброса',
        p6: 'Куда идут средства после покупки сделки?',
        p7: 'После завершения заказа на покупку средства поступят на счет в фиатной валюте в активе. Если вам нужно приобрести активы для торговли контрактами или фьючерсами, перейдите в модуль перевода средств для перевода.',
        p8: 'Зачем ограничивать количество изменений в прозвищах',
        p9: 'В настоящее время мы ограничиваем изменение псевдонима только один раз из соображений безопасности вашей сделки.',
        p10: 'Что это значит при передаче? Зачем нужен трансфер',
        p11: 'Чтобы обеспечить независимость средств между вашими счетами и облегчить разумный контроль рисков, мы разделяем счета каждого основного торгового модуля. Переводы относятся к процессу конвертации активов между торговыми счетами.',
        p12: 'Как рассчитывается капитал учетной записи пользовательского контракта?',
        p13: 'Чистая стоимость контрактного счета = маржинальная позиция плавающая прибыль и убыток на текущий счет',
        p14: 'Каково значение стоп-лосса и тейк-прибыли при открытии позиции? Как это должно быть установлено?',
        p15: 'Прибыль и убыток установлены для вас. Когда будет достигнута установленная сумма стоп-лосса, система автоматически закрывает позицию. Может использоваться для контроля рисков при покупке контракта. Установите половину суммы тейк: увеличьте количество x количество x количество рычага кратно, установите стоп-лосс. Мы рекомендуем вам настроить его в соответствии с вашим фактическим состоянием активов, чтобы разумно контролировать риск.',
        p16: 'Как снизить контрактные риски?',
        p17: 'Вы можете перевести доступные активы с оставшегося счета на контрактный счет путем перевода средств или уменьшить риск, сократив свои позиции',
        p18: 'Что означает количество открытых позиций по контракту?',
        p19: 'Количество открытых позиций представляет собой количество валюты, которую вы ожидаете купить. Например: на открытии страницы торговой пары BTC/USDT выберите, сделай длинную, введите цену 1000USDT, введите количество 10BTC, то есть: вы ожидаете купить 10BTC с ценой за единицу 1000USDT.',
        p20: 'Как рассчитывается плата за обработку в контракте?',
        p21: 'Плата за обработку = цена открытия позиции * количество открытых позиций * ставка комиссии',
        p22: 'Меры предосторожности при принудительной ликвидации',
        p23: 'Степень риска-это показатель риска ваших активов. Когда степень риска равна 100%, ваша позиция считается закрываемой, а степень риска = (удержание капитала на маржинальном/контрактном счете) * 100%. Чтобы пользователи не пересекли позицию, система устанавливает коэффициент риска для корректировки. Когда степень риска достигает значения риска, установленного системой, система будет принудительно закрывать позиции. Например: коэффициент настройки системы составляет 10%, тогда, когда ваш риск больше или равен 90%, все ваши позиции будут принудительно закрываться системой.',
        p24: 'Каковы правила торговли контрактами?',
        p25: 'Тип сделки',
        p26: 'Тип торговли делится на два направления: бык (покупка) и коротко (продажа):',
        p27: 'Покупка быка (бычий) означает, что вы думаете, что текущий индекс может расти, и вы хотите купить определенное количество определенного количества, которое подписывает контракт по цене, которую вы установили, или текущей рыночной цене.',
        p28: 'Под короткой продажей (медвежий) понимается, что вы считаете, что текущий индекс может упасть, и вы хотите продать определенное количество новых контрактов по цене, которую вы установили или по текущей рыночной цене.',
        p29: 'Способ заказа',
        p30: 'Ограничение цены заказа: вам нужно указать цену и количество заказа',
        p31: 'Рыночная цена: вам нужно только установить количество заказа, цена является текущей рыночной ценой',
        p32: 'Держать позиции',
        p33: 'Когда ваша отправленная инструкция по открытию позиции завершена, она называется удержания позиции',
        p34: 'Проблемы с доставкой по контракту',
        p35: 'Контракт на платформу является постоянным контрактом без времени доставки. Пока система не соответствует условиям сильного выравнивания или вы не закрыли позицию вручную, вы можете держать позицию навсегда. Система закрыла позицию',
        p36: '1: Достижение значения настройки стоп-лосса, система автоматически закрывает позицию',
        p37: '2: риск слишком высок, система принудительно закрыла позицию',
        p38: 'Какова маржа для сделки по контракту?',
        p39: 'В контрактных сделках пользователи могут участвовать в покупке и продаже контракта в соответствии с ценой и количеством контракта и соответствующим кратным кредитным плечом. Пользователь будет занимать маржу при открытии позиции. Чем больше маржа открытия, тем выше риск счета.',
        p40: 'Что такое ограниченная цена и рыночная цена?',
        p41: 'Ограничение цены относится к цене, по которой вы ожидаете доверить платформу для торговли, а рыночная цена относится к прямому поручению платформы торговать по текущей цене. В правилах открытия позиций рыночная цена имеет приоритет над лимитной ценой. Если вы выберете ограниченную цену, пожалуйста, откройте позицию в соответствии с текущей рыночной ценой валюты, чтобы избежать потерь из-за необоснованной цены открытия.',
        p42: 'Что означает уровень риска для контрактных сделок?',
        p43: 'Степень риска-это показатель риска в вашем контрактном счете. Степень риска равна 100% считается ликвидацией. Мы рекомендуем вам открывать позиции с осторожностью, когда ваш риск превышает 50%, чтобы избежать потерь из-за закрытия позиций. Вы можете уменьшить свой риск, пополняя имеющиеся средства в контрактных активах или уменьшая позиции.',
        p44: 'Зачем нужен обмен валюты?',
        p45: 'Цель биржи-обеспечить бесперебойное обращение средств в различных валютах в ваших активах. QCC, полученный на фьючерсном счете, можно свободно конвертировать в USDT для торговли. USDT на других счетах также можно свободно конвертировать в QCC для торговли.',
        p46: 'Почему другая сторона не получила перевод вовремя после перевода?',
        p47: 'Во-первых, убедитесь, что вы заполняете точно такой же способ оплаты, как и на странице сведений о заказе. Затем подтвердите, является ли ваш способ оплаты немедленной или отсроченной платежом. Наконец, обратитесь в свой банк и платежное учреждение для обслуживания системы или по другим причинам.',
        p48: 'Как купить USDT через платформу?',
        p49: 'Метод 1: Выберите заказ, который вы хотите купить, через список покупки и продажи платформы.',
        p50: 'Метод 2: Нажмите кнопку «Опубликовать», чтобы опубликовать заказ на покупку или заказ на продажу в соответствии с вашими потребностями для быстрой транзакции. Система будет быстро соответствовать пользователям покупки и продажи для вас.',
        p51: 'Если заказ на покупку не был оплачен через 15 минут, система автоматически отменит заказ, пожалуйста, оплатите вовремя. Заказ был отменен более 3 раз в тот же день, и он не может быть снова обменен в тот же день.'
    },
    public: {
        zxkf: 'Обслуживание клиентов онлайн',
        sure: 'Подтверждение',
        cancel: 'Отмена',
        RefreshSucceeded: 'Обновить успех',
        noMoreData: 'Больше нет данных.',
        pullingText: 'Спускаюсь, чтобы обновить...',
        ReleaseText: 'Освобождение для обновления...',
        loadingText: 'Загрузка в...',
        noData: 'Нет записей',
        xq: 'Подробная информация',
        submit: 'Подтверждение',
        kefu: 'Обслуживание клиентов онлайн'
    },
    ChangeType: {
        Upper: 'Система пополнения',
        Lower: 'Сниз системы',
        Win: 'Прибыль',
        Bet: 'Инвестиции',
        BuyMining: 'Майнинг',
        CoinWin: 'Новая прибыль',
        SettleMining: 'Майнинг возвращается',
        GiveInterest: 'Доходы от майнинга',
        BuyCoin: 'Новая валюта не была подписана',
        BuyCoinBack: 'Покупка новой валюты возвращается',
        UsdtToBtc: 'Usdt в Btc',
        UsdtToEth: 'Usdt в Eth',
        BtcToUsdt: 'Btc в Usdt',
        BtcToEth: 'Btc в Eth',
        EthToUsdt: 'Eth в Usdt',
        EthToBtc: 'Eth в Btc',
        CoinFrozen: 'Новая валюта заморожена',
        CoinThaw: 'Новая валюта оттаивает',
        CoinCancel: 'Отмена заказов на монеты',
        CoinBuy: 'Купить монеты',
        CoinBuyEntrust: 'Доверение покупки монет',
        CoinSellEntrust: 'Доверие к продаже монет',
        CoinSell: 'Продажа монет',
        ContractBuy: 'Контракт на заказ, вычет депозита',
        ContractCharge: 'Контракт на заказ, вычет комиссии',
        ContractCancelToBond: 'Возврат гарантийного депозита при расторжении контракта',
        ContractCancelToCharge: 'Возврат комиссии за аннулирование контракта',
        ContractCloseProfit: 'Прибыль от закрытия контракта'
    },
    trade: {
        title: 'Монета монета',
        Buy: 'Купить',
        Sell: 'Продать',
        pc: 'Закрывать позиции',
        cb: 'Стоимость',
        xj1: 'Текущая цена',
        yk: 'Прибыль и убыток',
        xianjia: 'Предельная цена',
        jiage: 'Цена',
        shuliang: 'Количество',
        keyong: 'Доступно',
        yonghuchicang: 'Пользователь позиции',
        fxlts: 'Коэффициент риска = маржа позиции/капитал клиента умножается на 100%, из которых капитал клиента равен доступным средствам на счете',
        fxl: 'Коэффициент риска',
        dqwt: 'Текущий ввод в эксплуатацию',
        all: 'Все.',
        name: 'Название',
        shijian: 'Время',
        zyjg: 'Торговля по текущей лучшей цене',
        caozuo: 'Операция',
        xj: 'Предельная цена',
        cc: 'Держать позиции',
        leixing: 'Тип',
        long: 'Делай больше',
        short: 'Короткие',
        sj: 'Рыночная цена',
        jiaoyie: 'Сумма сделки',
        orderNo: 'Номер заказа',
        weituojia: 'Вверенная цена',
        chengjiaojia: 'Цена сделки',
        chengjiaoe: 'Оборот',
        weituo: 'Метод ввода в эксплуатацию',
        qbwt: 'Все комиссии',
        lswt: 'Историческая комиссия',
        tips: 'Пожалуйста, введите правильную цену',
        tips1: 'Пожалуйста, введите количество',
        Create: 'В комиссии',
        Wait: 'В торговле',
        Complete: 'ОК.',
        Cancel: 'Удаление заказа',
        zsj: 'Стоп-лосс цена',
        zyj: 'Цена тейк-профит',
        cczsy: 'Общий доход от позиции',
        ykbl: 'Коэффициент прибылей и убытков',
        bili: 'Пропорция',
        jyss: 'Количество лотов',
        bzj: 'Маржа',
        sxf: 'Плата за обработку',
        gg: 'Множение рычага',
        yjpc: 'Одна кнопка закрыла позицию'
    },
    auth: {
        title: 'Идентификация личности',
        title1: 'Идентификация личности',
        low: 'Первичная сертификация',
        high: 'Расширенные сертификаты',
        success: 'Сертификация прошла успешно',
        tips: 'Пожалуйста, сначала сделайте первичную сертификацию',
        tips1: 'Введите настоящее имя',
        tips2: 'Введите идентификационный номер',
        tips3: 'Пожалуйста, загрузите положительную фотографию удостоверения личности',
        tips4: 'Пожалуйста, загрузите фотографию на обратной стороне удостоверения личности',
        tips5: 'Пожалуйста, загрузите ручную фотографию',
        qrz: 'На сертификацию',
        shz: 'На рассмотрении',
        rzsb: 'Сбой сертификации',
        userName: 'Настоящее имя',
        IDNumber: 'Идентификационный номер',
        zmz: 'Передняя фотография удостоверения личности',
        bmz: 'Фотография на обратной стороне удостоверения личности',
        scz: 'Ручная фотография'
    },
    share: {
        title: 'Мой рекламный код',
        cp: 'Копирование адреса',
        success: 'Успешное копирование'
    },
    property: {
        Contract: 'Монета монета',
        Exchange: 'Второй контракт',
        zzc: 'Общий объем активов конвертируется',
        mhyzh: 'Секунды Контрактный счет',
        bbzh: 'Монетный счет',
        available: 'Доступно',
        OnOrders: 'Замороженные',
        Estimated: 'Складка',
        cw: 'Финансовые отчеты',
        number: 'Количество',
        record: 'Запись',
        Transfer: 'Переню',
        time: 'Время',
        dhsl: 'Количество обмена',
        tips: 'Введите количество выкупа',
        qbdh: 'Обмен всех',
        dhhl: 'Обменные курсы',
        ky: 'Доступно',
        kd: 'Доступно',
        sxfbl: 'Пропорция платы за обработку',
        sxf: 'Плата за обработку'
    },
    statusLang: {
        Review: 'Аудит',
        Success: 'Успех',
        Fail: 'Провал'
    },
    with: {
        title: 'Сниз',
        history: 'Запись о снятии средств',
        ldz: 'Цепочный адрес',
        tbdz: 'Адрес монеты',
        tips: 'Введите адрес монеты',
        tips1: 'Введите количество монет',
        tips2: 'Введите пароль для оплаты',
        tbsl: 'Количество монет',
        ky: 'Доступно',
        zjmm: 'Платежный пароль',
        sxf: 'Плата за обработку',
        dz: 'Предполагаемое количество поступит'
    },
    tabBar: {
        home: 'Главная страница',
        Quotation: 'Цитаты',
        Quotation2: 'Рыночные котировки',
        Trade: 'Монета монета',
        Contract: 'Второй контракт',
        assets: 'Активы',
        qhyy: 'Переключение языков',
        zyjb: 'Локканский майнинг',
        jsjy: 'Скоростная торговля',
        jryw: 'Финансовые операции',
        hyjy: 'Контрактные сделки',
        bbjy: 'Обмен валютами'
    },
    home: {
        UserName: 'Счет',
        kjmb: 'Быстро купить монеты',
        score: 'Кредитные баллы',
        zc: 'Поддержка BTC, USDT и др.',
        scwk: 'Локканский майнинг',
        sgxb: 'Покупка новой валюты',
        aqkx: 'Безопасный и заслуживающий доверия',
        aqkx1: 'Ведущая в мире платформа для торговли цифровыми активами',
        zfb: 'Список повышения',
        dfb: 'Список падений',
        mc: 'Название',
        hy: 'Контракт',
        zxj: 'Последняя цена',
        zdb: 'Вверх и вниз',
        sfyz: 'Идентификация личности',
        skfs: 'Способ получения',
        share: 'Моя ссылка для обмена',
        kjcb: 'Быстрая зарядка монет',
        kjtb: 'Быстрый подсчет монет',
        qbdz: 'Адрес кошелька',
        bps: 'Белая книга',
        xgmm: 'Изменение пароля',
        xgzfmm: 'Изменение платежного пароля',
        gywm: 'О нас',
        tcdl: 'Выход'
    },
    quotation: {
        title: 'Цитаты',
        mc: 'Количество',
        zxj: 'Последняя цена',
        zdb: 'Вверх и вниз',
        liang: 'Количество'
    },
    contract: {
        kcslfw: 'Количество открытых позиций:',
        gao: 'Высокая',
        di: 'Низкий',
        liang: 'Количество 24H',
        placeholder: 'Пожалуйста, введите количество',
        SuccessfulPurchase: 'Успешная покупка',
        smallMin: 'Минута.',
        hour: 'Час',
        dayMin: 'Боже!',
        Trend: 'Разделение времени',
        ccjl: 'Список позиций',
        ddjl: 'Запись заказа',
        sl: 'Количество',
        gmj: 'Цена покупки',
        dqj: 'Текущая цена',
        yjyk: 'Ожидаемая прибыль и убыток',
        djs: 'Обратный отсчет',
        gbjg: 'Цена сделки',
        sc: 'Плата за обработку',
        pal: 'Прибыль и убыток',
        mhy: 'Второй контракт',
        sj: 'Время',
        yll: 'Прибыль',
        mz: 'Купить',
        md: 'Купить падение',
        kcsl: 'Количество открытых позиций',
        kcsj: 'Время открытия',
        zhye: 'Остаток на счете',
        qc: 'Отмена'
    },
    editPassword: {
        title: 'Изменение пароля',
        jmm: 'Старый пароль',
        xmm: 'Новый пароль',
        qrmm: 'Подтверждение пароля',
        placeholder: 'Старый пароль',
        placeholder1: 'Установка нового пароля',
        placeholder2: 'Введите новый пароль',
        qrxf: 'Подтверждение'
    },
    withPass: {
        title: 'Пароль для снятия средств',
        jmm: 'Старый пароль',
        xmm: 'Новый пароль',
        qrmm: 'Подтверждение пароля',
        placeholder: 'Старый пароль',
        placeholder1: 'Установка нового пароля',
        placeholder2: 'Введите новый пароль',
        qrxf: 'Подтверждение',
        tips: 'Введите старый пароль как минимум 6 цифр',
        tips1: 'Введите новый пароль по крайней мере 6 цифр',
        tips2: 'Введите правильный пароль для подтверждения',
        tips3: 'Новые пароли, введенные дважды, не совпадают',
        tips4: 'Установить пароль успешно',
        confirm: 'Подтверждение'
    },
    newBe: {
        title: 'Покупка новой валюты',
        title1: 'Моя покупка',
        scqx: 'Срок блокировки склада',
        day: 'Боже!',
        sy: 'Остаток',
        sdsg: 'Запись',
        sysl: 'Оставшаяся сумма',
        gmsl: 'Количество покупок',
        dj: 'Цена за единицу',
        tips: 'Введите количество покупок',
        gmzj: 'Общая стоимость покупки',
        qbye: 'Баланс на кошельке',
        fmzj: 'Общая стоимость покупки',
        gm: 'Купить',
        sgsj: 'Время покупки',
        Currency: 'Валюта',
        Request: 'Количество заявок',
        Passing: 'По количеству',
        MarketTime: 'Время выхода на рынок',
        sqz: 'Заявка'
    },
    register: {
        title: 'Регистрация',
        lang: 'Язык',
        send: 'Отправить',
        Resend: 'Повторная отправка',
        Account: 'Счет',
        Password: 'Пароль',
        ConfirmPassword: 'Подтверждение пароля',
        InvitationCode: 'Код приглашения',
        btn: 'Регистрация',
        amount: 'Номер счета',
        mailbox: 'Почтовый ящик',
        haveAccount: 'Есть ли аккаунт?',
        Login: 'Войти',
        placeholder: 'Введите адрес электронной почты',
        placeholder0: 'Пожалуйста, введите номер счета',
        placeholder1: 'Введите пароль',
        placeholder2: 'Введите пароль подтверждения',
        placeholder3: 'Введите код приглашения',
        placeholder4: 'Введите код подтверждения почтового ящика',
        placeholder5: 'Пожалуйста, введите правильный адрес электронной почты',
        success: 'Успешная регистрация',
        message: 'Пароль не менее 6 цифр',
        message1: 'Подтвердите пароль не менее 6 цифр',
        message2: 'Пароль не совпадает дважды',
        message3: 'Пожалуйста, прочитайте и отметьте протокол',
        agree: 'Я согласен.',
        xy: 'Пользовательское соглашение и политика конфиденциальности',
        qr: 'Подтверждение'
    },
    lockming: {
        title: 'Список замков',
        wkb: 'Копать сокровища',
        wkgjd: 'Майнинг проще',
        qsljl: 'Легко получить награды',
        yjzsr: 'Прогнозируемый общий доход',
        ck: 'Год за последние 3 дня',
        tips: 'По крайней мере,',
        rlx: 'Досный интерес',
        day: 'Боже!',
        scqx: 'Срок блокировки склада',
        buy: 'Купить',
        zysl: 'Количество залога',
        qbye: 'Баланс на кошельке',
        finished: 'Завершение',
        doing: 'В процессе...',
        sl: 'Количество',
        shouyilv: 'Доходность',
        ks: 'Время начала',
        js: 'Время окончания'
    },
    login: {
        title: 'Войти',
        lang: 'Язык',
        Account: 'Счет',
        Password: 'Пароль',
        btn: 'Войти',
        placeholder1: 'Введите пароль',
        placeholder: 'Пожалуйста, введите номер счета',
        fail: 'Пожалуйста, заполните полную форму',
        ForgotPassword: 'Забыли пароль',
        NoAccount: 'У вас еще нет аккаунта?',
        Registration: 'Регистрация',
        server: 'Обслуживание клиентов онлайн',
        jzmm: 'Запомните пароль',
        success: 'Успешный вход в систему',
        Home: 'Главная страница'
    },
    moneyAddr: {
        title: 'Адрес кошелька',
        Currency: 'Валюта',
        Address: 'Адрес',
        placeholder: 'Пожалуйста, выберите',
        DepositAddress: 'Адрес наполнения монет',
        WalletAddress: 'Адрес кошелька',
        Date: 'Время',
        add: 'Добавить адрес кошелька',
        ChainAddress: 'Цепочный адрес',
        qrcode: 'Двумерный код кошелька',
        submit: 'Подтверждение',
        tips: 'Выберите тип кошелька',
        tips1: 'Выберите тип адреса цепочки',
        tips2: 'Введите адрес кошелька',
        tips3: 'Пожалуйста, загрузите двумерный код кошелька',
        success: 'Успех'
    },
    recharge: {
        title: 'Перезарядка',
        title1: 'Детали перезарядки',
        ls: 'Детали перезарядки',
        td: 'Пожалуйста, выберите следующий канал пополнения',
        bc: 'Сохранить QR-код',
        address: 'Адрес наполнения монет',
        copy: 'Копирование адреса',
        num: 'Количество наполненных монет',
        Upload: 'Загрузить детали оплаты скриншоты',
        Submit: 'Подтверждение',
        tip: 'Пожалуйста, введите количество',
        tip1: 'Пожалуйста, загрузите скриншоты',
        copySuc: 'Успешное копирование'
    },
    newChange: {
        num: 'Пожалуйста, введите количество',
        psd: 'Введите пароль',
        hq: 'Цитаты',
        bb: 'Центр помощи',
        sec: 'Сертификация реального имени',
        ass: 'Активы',
        pool: 'Финансовый менеджмент',
        newc: 'О нас',
        rechange: 'Перезарядка',
        with: 'Обслуживание клиентов',
        help: 'Центр помощи',
        a1: 'Финансы',
        a2: 'Средства в заключении (USDT)',
        a3: 'Сегодняшняя прибыль',
        a4: 'Совокупный доход',
        a5: 'В процессе',
        a6: 'Заказ',
        a7: 'Правила',
        a8: 'Ежедневный возврат',
        a9: 'Одиночное ограничение',
        a10: 'Цикл',
        a11: 'Pecently (Ежедневный доход)',
        a12: 'Отдельные ограничения',
        a13: 'Количество хостинга:',
        a14: 'Подписка',
        a15: 'Расчет доходов',
        a16: 'Переливание кирпича осуществляется через хостинг USDT на платформу, платформа имеет профессиональную команду для перемещения кирпича...',
        a17: 'Участник набрал 10000U на платформе и выбрал продукт по управлению активами со сроком погашения 5 дней и ежедневной доходностью от 0,3% до 0,5%. Ежедневный максимум: 10000U x 0,5% = 50 U n, то есть доход 150U-250U может быть получен через 5 дней. Срок действия депозита истек, после чего будет...',
        a18: 'О возмещении убытков',
        a19: 'Если вы хотите перевести непогашенный основной капитал, это приведет к возмещению убытков. Ликвидированные убытки = коэффициент ликвидации по умолчанию * Оставшиеся дни * Количество инвестиций. N Пример: коэффициент расчета по умолчанию для продукта составляет 0,4%, оставшиеся 3 дня истекают, а сумма инвестиций составляет 1000, тогда ликвидированные убытки = 0,4% * 3...',
        a20: 'Я хочу присоединиться.'
    },
    msgbank: {
        msgTitle: 'Сообщение',
        noticeTitle: 'Объявление',
        msgContent: ' У вас есть непрочитанное сообщение',
        msgBtn: 'Иди посмотри',
        bankCard: 'Банковская карта',
        inputTips: 'Пожалуйста, введите полную информацию',
        submitSuccess: 'Отправить успешно',
        CardUserName: 'Имя владельца счета',
        BankName: 'Открытие банка',
        OpenBank: 'Точка открытия',
        CardNo: 'Номер банковской карты',
        noBindBankmsg: 'Вы еще не привязали банковскую карту',
        noBindBankBtn: 'Перейти к привязке',
        modify: 'Изменения',
    }
}