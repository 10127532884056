module.exports = {
    pcregister: {
        p1: 'すでにアカウントをお持ちですか?',
        dl: 'ログイン',
        zc: '登録',
        p4: 'アカウント',
        p5: '口座番号を入力してください',
        p6: 'パスワードを入力してください',
        p7: '確認用パスワードを入力してください',
        p8: '招待コードを入力してください',
        p9: '同意します',
        p10: '"利用規約とプライバシー ポリシー"',
        p11: 'メールボックス',
        p12: 'メールアドレスを入力してください',
        p13: 'メール認証コードを入力してください',
        p14: '送信',
        p15: '年',
        p16: '月',
        p17: '空',
        p18: '時間',
        p19: '分',
        p20: '秒'
    },
    pclogin: {
        l1: 'アカウントがありませんか?',
        l2: '登録',
        l3: 'ログイン',
        l4: 'ユーザー名を入力してください',
        l5: 'パスワードを入力してください',
        l6: 'パスワードを忘れました',
        l7: 'ログイン'
    },
    pccontract: {
        ss: '市場価格',
        xj: '指値',
        gg: 'レバレッジ'
    },
    pcrapidly: {
        zf: '増加',
        dd: 'オーダー',
        bdsj: '現地時間',
        ky: '利用可能'
    },
    pchome: {
        global1: '世界をリードするデジタル資産取引プラットフォーム',
        global2: '安全で信頼できるデジタル資産取引と資産管理サービスを 100 か国以上の何百万人ものユーザーに提供しています。',
        global3: '安全で信頼できる',
        global4: 'デジタル資産金融サービスにおける 5 年間の経験。 分散アーキテクチャとアンチ DDoS 攻撃システムに焦点を当てます。 ',
        global5: 'グローバルな生態学的レイアウト',
        global6: '複数のビジネス フォーマットを統合するブロックチェーン エコシステムを作成するために、ローカライズされたトランザクション サービス センターが多くの国で設立されています。',
        global7: 'ユーザーファースト',
        global8: '事前補償メカニズムを確立し、投資家保護基金を設立します。 ',
        where1: 'いつでも、どこでもマルチプラットフォーム端末トランザクション',
        where2: 'iOS、Android、Windows などの複数のプラットフォームをカバーし、完全なビジネス機能をサポートします。 ',
        register1: '登録する',
        register3: 'グローバル アカウントが取引を開始します',
        register2: '今すぐ登録'
    },
    pcbottom: {
        box1: 'グローバルリーダー',
        box2: '最高チームの1つ',
        box3: 'もっと',
        box4: '私たちについて',
        box5: 'ホワイトペーパー',
        box6: 'サービス',
        box7: 'ダウンロード',
        box8: 'よくある質問',
        box9: 'オンラインカスタマーサービス',
        box10: '要件',
        box11: '利用規約',
        box12: 'プライバシーポリシー',
        box13: '法的通知',
        box14: '接続'
    },
    pcAbout_us: {
        t1: '進捗状況',
        t2: '私たちは、7年間の道のり',
        t3: '2015年の誕生以来、フルタイムのテクノロジーとチャネル構築サービスでブロックチェーンを提供することに専念してきました。',
        t4: '2014-2017年经历了“稳中有升”的上行期，2017-2018年上半期は「お金を買って金持ちになろう」という狂気の時代でしたが、2019年下半期から今日の寒い冬にかけて、本領を発揮してきました。 多くの成功したパートナーが、業界で控えめな評判を私たちに与えてくれました. 2022 年には、まったく新しいプラットフォーム V3 が登場します。.0 を設立し、世界 100 か国以上で事業を展開する新しい合資会社 BitGoldEX を設立しました。',
        t5: '私たちは誰を探していますか',
        t6: '創業から今日まで',
        t7: '私は、困難を抱えていて自分と同じ趣味を持つサービスプロバイダーを探していて、数年間で数十のサービスプロバイダーの問題を解決してきました. ',
        t8: '今日の合理性において、あなたが自分自身を認識し、前進するために一緒に働くことができる良いヘルパー、パートナーを見つけたい場合は、おそらく私たちに相談することができます.',
        t9: 'あなたが探している人はあなたを探していると信じてください。',
        t10: '仕える',
        t11: 'セールスマンはいないが、ビジネスはある',
        t12: '友人があなたにビジネスを紹介するとき、それはあなたが良い人であることを意味します. 同僚からビジネスを紹介された場合、それはあなたの評判が良いことを意味します。 クライアントがあなたにビジネスを紹介するとき、それはあなたが信頼できることを証明します.',
        t13: '精神',
        t14: '俺たちはレンガだ、どこへ行く必要もない',
        t15: '顧客が異なれば、要件も異なります。 異なる製品には異なる特性があります。 時代が違えば目的も違う。 私たちの存在意義は、さまざまなサービスプロバイダーのコンセプト、システム、および要件に適応し、さまざまな問題を解決することです。',
        t16: '私たちは考えと機能を備えたレンガになりたいと思っています。必要があればどこにでも行きます。'
    },
    pcproblem: {
        p1: 'よくある問題',
        p2: '変換されたアセットの数が変わるのはなぜですか?',
        p3: '資産における等価計算は、現在保有しているデジタル通貨を USDT に換算した値であり、デジタル資産の価格変動によって変化します。 デジタル資産の数は変更されていません。',
        p4: 'プラットフォーム取引でファンドのパスワードを忘れた場合の対処方法',
        p5: 'プラットフォーム取引資金のパスワードを忘れた場合は、「マイ-設定-「ファンドパスワードの設定」をクリックするか、カスタマーサービスに連絡してリセットしてください',
        p6: 'トランザクションの購入後、お金はどこに行きますか?',
        p7: '注文書が完了すると、資金は資産の法定通貨口座に送られます。 限月取引または先物取引のために資産を購入する必要がある場合は、資金移動モジュールに移動して移動してください。',
        p8: 'ニックネームの変更回数を制限する理由',
        p9: '取引のセキュリティのため、現在、ニックネームの変更は 1 回のみに制限されています。',
        p10: '転送 とはどういう意味ですか? 転送する理由',
        p11: 'アカウント間の資金の独立性を確保し、合理的なリスク管理を容易にするために、主要な取引モジュールごとにアカウントを分割します。 転送とは、取引口座間の資産変換のプロセスを指します。',
        p12: 'ユーザー契約のアカウントエクイティはどのように計算されますか?',
        p13: '契約口座純額＝ポジション証拠金＋ポジション変動損益＋当座預金利用可能額',
        p14: 'ポジションを開くときのストップロスとテイクプロフィット設定の値は何ですか? どのように設定する必要がありますか？',
        p15: '利益と損失をあなたの利益と損失の上限としてください。 設定されたストップ利益とストップロスの金額に達すると、システムは自動的にポジションをクローズします。 契約を購入する際のリスク管理に使用できます。 テイク プロフィット額の半分を設定: 額 x 数量 x レバレッジ倍数を増やし、ストップ ロスを設定します。 実際の資産に応じて設定し、合理的にリスクを管理することをお勧めします。',
        p16: '契約リスクを減らすには？',
        p17: '残りの口座の利用可能な資産を資金を移動することで契約口座に移すか、ポジションを減らすことでリスクを減らすことができます',
        p18: '契約開始枚数とは？',
        p19: '建玉の金額は、購入したい通貨の金額を表します。 例: BTC/USDT 取引ペアの開始ページでロングを選択し、入力価格は 1000USDT、入力数量は 10BTC です。つまり、10 BTC を購入する予定で、単価は 1000USDT です。',
        p20: '契約における手数料はどのように計算されますか?',
        p21: '手数料＝開封価格×開封数量×手数料率',
        p22: '強制清算の注意事項',
        p23: 'リスクレベルは、資産のリスクの尺度です。 リスク レベルが 100% に等しい場合、ポジションはクローズされたと見なされます。リスク レベル = (保有証拠金 / 契約口座の資産) * 100%。ユーザーがポジションをオーバーランするのを防ぐために、システムはリスク レベルの比率を調整します。 リスクレベルがシステムによって設定されたリスク値に達すると、システムはポジションを強制的にクローズします。 例: システムによって設定された調整率が 10% の場合、リスク度が 90% 以上になると、すべてのポジションがシステムによって強制的にクローズされます。',
        p24: '契約取引のルールは何ですか?',
        p25: '取引タイプ',
        p26: '取引タイプは、ロング (買い) とショート (売り) の方向に分けられます。',
        p27: '買いロング（強気）とは、現在の指数が上昇する可能性が高いと信じており、設定した価格または現在の市場価格で契約を締結するために一定額の一定額を購入することを意味します。',
        p28: '空売り (弱気) とは、現在のインデックスが下落する可能性があると考えており、設定した価格または現在の市場価格で一定数の新規契約を売りたいと考えていることを意味します。',
        p29: '注文方法',
        p30: '指値注文：注文の価格と数量を指定する必要があります',
        p31: '成行注文：注文数量のみを設定する必要があり、価格は現在の市場価格です',
        p32: '位置',
        p33: 'ポジションを開くために送信した注文が完了すると、ポジションと呼ばれます',
        p34: '契約配送の問題',
        p35: 'プラットフォーム契約は、納期のない無期限契約です。 システムが強制清算条件を満たさない限り、またはポジションを手動でクローズしない限り、ポジションを永久に保持できます。 システムクローズ',
        p36: '1: ストッププロフィットとストップロスの設定値に達すると、システムは自動的にポジションをクローズします',
        p37: '2：リスクが高すぎる、システムが清算を強制する',
        p38: '契約取引の証拠金はいくらですか？',
        p39: 'コントラクト取引では、ユーザーはコントラクトの価格と数量、および対応するレバレッジに従って、コントラクトの取引に参加できます。 ユーザーがポジションを開くと、証拠金が占有されます。 開始証拠金が大きいほど、口座のリスクが高くなります。',
        p40: '指値注文と成行注文とは？',
        p41: '指値注文とは、プラットフォームに取引を委託することを期待する価格を指し、成行注文とは、プラットフォームに現在の価格で取引を行うことを直接委託することを指します。 成行注文は、注文開始ルールにおいて指値注文よりも優先されます。 指値注文を選択する場合は、不当な始値による損失を避けるために、通貨の現在の市場価格に従って合理的にポジションを開いてください。',
        p42: '契約取引のリスクレベルは何を表していますか?',
        p43: 'リスクは、契約アカウントのリスク指標です。 100% に等しいリスク レベルは、清算されたと見なされます。 リスクが 50% を超える場合は、ポジションを閉じることによる損失を避けるために、慎重にポジションを開く必要があることをお勧めします。 契約資産で利用可能な資金を補充するか、ポジションを減らすことで、リスクを軽減できます。',
        p44: 'なぜ外貨両替が必要なの？',
        p45: '交換の目的は、資産内の異なる通貨での資金の円滑な循環を可能にすることであり、先物口座で取得した QCC は自由に USDT に変換して取引することができます。 他のアカウントのUSDTも、取引のためにQCCに自由に変換できます。',
        p46: '振込後、相手方に振込が間に合わなかったのはなぜですか？',
        p47: 'まず、お振込みの際にご入力いただくお支払い方法が、ご注文詳細ページに表示されるお支払い方法と同一であることをご確認ください。 次に、支払い方法が即時払いか後払いかを確認してください。 最後に、システム メンテナンスやその他の理由で、ご利用の銀行および決済機関にお問い合わせください。',
        p48: 'プラットフォームを通じてUSDTを購入する方法は?',
        p49: '方法 1: プラットフォームの売買リストから購入する注文を選択して、売買します。',
        p50: '方法 2: 発行ボタンをクリックして、迅速な取引のニーズに応じて売買注文を発行します。 システムは、買い手と売り手を迅速にマッチングします。',
        p51: '注: 15 分経過しても購入注文が支払われない場合、システムは自動的に注文をキャンセルします。時間内にお支払いください。 同一日に3回以上キャンセルされた場合、同日に再度取引することはできず、翌日に取引権限が回復されます。'
    },
    public: {
        zxkf: 'オンラインサービス',
        sure: '確認',
        cancel: 'キャンセル',
        RefreshSucceeded: '正常に更新されました',
        noMoreData: 'もうデータはありません',
        pullText: 'プルダウンして更新...',
        ReleaseText: 'リリースして更新...',
        loadingText: '読み込み中...',
        noData: '記録なし',
        xq: '詳細',
        submit: '確認',
        kefu: 'オンラインサービス'
    },
    ChangeType: {
        Upper: 'システムの再充電',
        Lower: 'システム撤退',
        Win: '利益',
        Bet: '投資',
        BuyMining: 'マイニング',
        CoinWin: 'シンガポール通貨の利益',
        SettleMining: 'マイニングプリンシパルリターン',
        GiveInterest: 'マイニング収入',
        BuyCoin: 'シンガポール通貨は宝くじに当選しなかった',
        BuyCoinBack: 'シンガポール通貨購入返品',
        UsdtToBtc: 'Usdt to Btc',
        UsdtToEth: 'Usdt から Eth',
        BtcToUsdt: 'Btc から USDT',
        BtcToEth: 'Btc to Eth',
        EthToUsdt: 'Eth から USDT',
        EthToBtc: 'Eth から Btc',
        CoinFrozen: '新しい通貨宝くじ凍結',
        CoinThaw: 'シンガポール通貨の凍結解除',
        CoinCancel: 'コイン取消注文',
        CoinBuy: 'コイン購入',
        CoinBuyEntrust: 'コイン購入委託',
        CoinSellEntrust: 'コイン売り注文',
        CoinSell: 'コイン販売',
        ContractBuy: '契約注文、証拠金を差し引く',
        ContractCharge: '契約注文、手数料の控除',
        ContractCancelToBond: '契約のキャンセルは保証金を返します',
        ContractCancelToCharge: '契約解除料の返金',
        ContractCloseProfit: '契約終了利益'
    },
    trade: {
        title: '通貨',
        Buy: '買う',
        Sell: '売る',
        pc: 'ポジションを閉じる',
        cb: '料金',
        xj1: '現在の価格',
        yk: '利益と損失',
        xianjia: '指値価格',
        jiage: '価格',
        shuliang: '量',
        keyong: '利用可能',
        yonghuchicang: 'ユーザーの位置',
        fxlts: 'リスク率 = ポジション証拠金 / 顧客資本に 100% を掛けたもの。ここで、顧客資本は口座の利用可能な資金 + 初期取引証拠金 + 浮動損益に等しい',
        fxl: 'リスク率',
        dqwt: '現在の手数料',
        all: '全て',
        name: '名前',
        shijian: '時間',
        zyjg: '現在の最良の価格で取引する',
        caozuo: '操作する',
        xj: '価格制限',
        cc: '持仓',
        leixing: 'タイプ',
        long: 'もっとやる',
        short: '短い',
        sj: '市場価格',
        jiaoyie: '取引金額',
        orderNo: '注文番号',
        weituojia: '手数料価格',
        chengjiaojia: '最終価格',
        chengjiaoe: 'ひっくり返す',
        weituo: '委託方法',
        qbwt: 'すべてを委ねる',
        lswt: '歴史的委員会',
        tips: '正しい価格を入力してください',
        tips1: '数量を入力してください',
        Create: '託された',
        Wait: '取引中',
        Complete: 'クローズドポジション',
        Cancel: 'キャンセル',
        zsj: 'ストップロス価格',
        zyj: 'テイクプロフィット価格',
        cczsy: '位置総収入',
        ykbl: '損益率',
        bili: '比率',
        jyss: 'トレーディングロット',
        bzj: 'マージン',
        sxf: '手数料',
        gg: 'レバレッジ倍数',
        yjpc: 'ワンクリックでポジションをクローズ'
    },
    auth: {
        title: '認証',
        title1: '認証',
        low: '一次認証',
        high: '高度な認定',
        success: '認証成功',
        tips1: '本名を入力してください',
        tips2: 'IDカード番号を入力してください',
        tips3: '身分証明書の正面写真をアップロードしてください',
        tips4: '身分証明書の裏面の写真をアップロードしてください',
        tips5: '手持ちの写真をアップロードしてください',
        qrz: '認証に進む',
        shz: '審査中',
        rzsb: '認証に失敗しました',
        userName: '本名',
        IDNumber: 'IDカード番号',
        zmz: 'ID カード正面写真',
        bmz: 'ID カードの裏面の写真',
        scz: '手の写真'
    },
    share: {
        title: '私のプロモーションコード',
        cp: 'アドレスをコピー',
        success: '正常にコピー'
    },
    property: {
        Contract: '通貨',
        Exchange: '二度目の契約',
        zzc: '総資産相当',
        mhyzh: '2回目の契約アカウント',
        bbzh: '通貨口座',
        available: '利用可能',
        OnOrders: '氷結',
        Estimated: '変換',
        cw: '財務記録',
        number: '量',
        record: '記録',
        Transfer: '移行',
        time: '時間',
        dhsl: '交換金額',
        tips: '両替金額を入力してください',
        qbdh: 'すべてを償還する',
        dhhl: '為替レート',
        ky: '使用可能',
        kd: '利用可能',
        sxfbl: '手数料率',
        sxf: '手数料'
    },
    statusLang: {
        Review: 'レビュー',
        Success: '成功',
        Fail: '不合格'
    },
    with: {
        title: '退却',
        history: '出金記録',
        ldz: 'チェーンアドレス',
        tbdz: '出金先住所',
        ヒント: '出金先住所を入力してください',
        tips1: '出金額を入力してください',
        tips2: '支払いパスワードを入力してください',
        tbsl: '出金金額',
        ky: '利用可能',
        zjmm: '支払いパスワード',
        sxf: '手数料',
        dz: '概算金額'
    },
    tabBar: {
        home: '表紙',
        Quotation: '引用符',
        Quotation2: '市場',
        Trade: '通貨',
        Contract: '二度目の契約',
        assets: '資産',
        qhyy: '言語を切り替える',
        zyjb: 'ロックマイニング',
        jsjy: '極端な取引',
        jryw: '金融業務',
        hyjy: '契約取引',
        bbjy: 'コイン取引です'
    },
    home: {
        UserName: 'アカウント',
        kjmb: 'コインを素早く購入する',
        score: 'クレジットスコア',
        zc: 'BTC、USDTなどをサポート',
        scwk: 'ロックマイニング',
        sgxb: 'SGD サブスクリプション',
        aqkx: '安全で信頼できる',
        aqkx1: '世界をリードするデジタル資産取引プラットフォーム',
        zfb: 'ゲイン一覧',
        dfb: '下落幅ランキング',
        mc: '名前',
        hy: '契約する',
        zxj: '最新の価格',
        zdb: '見積もり変更',
        sfyz: '認証',
        skfs: '支払方法',
        share: '私の共有リンク',
        kjcb: 'クイック入金',
        kjtb: 'クイック引き出し',
        qbdz: 'ウォレットアドレス',
        bps: '白書',
        xgmm: 'パスワードを変更する',
        xgzfmm: '支払いパスワードの変更',
        gywm: '私たちに関しては',
        tcdl: '終了する'
    },
    quotation: {
        title: '引用符',
        mc: '量',
        zxj: '最新の価格',
        zdb: '見積もり変更',
        liang: '量'
    },
    contract: {
        kcslfw: '開始量の範囲:',
        gao: '高い',
        di: '低い',
        liang: '24時間ボリューム',
        placeholder: '数量を入力してください',
        SuccessfulPurchase: '成功した購入',
        smallMin: '分',
        hour: '時間',
        dayMin: '空',
        Trend: '時間を共有する',
        ccjl: '役職一覧',
        ddjl: '注文記録',
        sl: '量',
        gmj: '購入金額',
        dqj: '現在の価格',
        yjyk: '推定損益',
        djs: '秒読み',
        gbjg: '最終価格',
        sc: '手数料',
        pal: '利益と損失',
        mhy: '二度目の契約',
        sj: '時間',
        yll: '収益性',
        mz: '買い占めます',
        md: '短く買う',
        kcsl: '開口量',
        kcsj: '開始時間',
        zhye: '勘定残高',
        qc: 'キャンセル'
    },
    editPassword: {
        title: 'パスワードを変更する',
        jmm: '以前のパスワード',
        xmm: '新しいパスワード',
        qrmm: 'パスワードを認証する',
        placeholder: '以前のパスワード',
        placeholder1: '新しいパスワードを設定',
        placeholder2: '新しいパスワードを再入力してください',
        qrxf: '確認'
    },
    withPass: {
        title: '出金パスワード',
        jmm: '以前のパスワード',
        xmm: '新しいパスワード',
        qrmm: 'パスワードを認証する',
        placeholder: '以前のパスワード',
        placeholder1: '新しいパスワードを設定',
        placeholder2: '新しいパスワードを再入力してください',
        qrxf: '確認',
        tips: '古いパスワードを 6 文字以上で入力してください',
        tips1: '6文字以上の新しいパスワードを入力してください',
        tips2: '正しい確認パスワードを入力してください',
        tips3: '2 回入力した新しいパスワードが一致しません',
        tips4: 'パスワードが正常に設定されました',
        confirm: '確認'
    },
    newBe: {
        title: 'SGD サブスクリプション',
        title1: '私のサブスクリプション',
        scqx: 'ロックアップ期間',
        day: '空',
        sy: '残り',
        sdsg: '記録',
        sysl: '残りの金額',
        gmsl: '購入数量',
        dj: '単価',
        tips: '購入数量を入力してください',
        gmzj: '合計購入金額',
        qbye: 'ウォレット残高',
        fmzj: '合計購入金額',
        gm: '買う',
        sgsj: '購読時間',
        Currency: '通貨',
        Request: '応募数',
        Passing: '通過量',
        MarketTime: '市場投入までの時間',
        sqz: '適用中'
    },
    register: {
        title: '登録',
        lang: '言語',
        send: '送信',
        Resend: '再送',
        Account: 'アカウント',
        Password: 'パスワード',
        ConfirmPassword: 'パスワードを認証する',
        InvitationCode: '招待コード',
        btn: '登録',
        amount: 'アカウント',
        mailbox: '郵便',
        haveAccount: 'すでにアカウントをお持ちですか？',
        Login: 'ログイン',
        placeholder: 'メールアドレスを入力してください',
        placeholder0: 'アカウントを入力してください',
        placeholder1: 'パスワードを入力してください',
        placeholder2: '確認パスワードを入力してください',
        placeholder3: '招待コードを入力してください',
        placeholder4: 'メール認証コードを入力してください',
        placeholder5: '有効なメールアドレスを入力してください',
        success: '登録完了',
        message: 'パスワード 6 文字以上',
        message1: 'パスワードの確認 6 文字以上',
        message2: '2 つのパスワードが一致しません',
        message3: '同意書を読んでチェックを入れてください',
        agree: '同意します',
        xy: 'ユーザー同意書とプライバシーポリシー',
        qr: '確認'
    },
    lockming: {
        title: 'ロックアップリスト',
        wkb: '採掘の宝',
        wkgjd: 'マイニングがより簡単に',
        qsljl: '報酬を受け取りやすい',
        yjzsr: '推定総利益',
        ck: '過去 3 日間の参照年換算',
        tips: '少なくとも ',
        rlx: '日利',
        day: '空',
        scqx: 'ロックアップ期間',
        buy: '買う',
        zysl: 'プレッジ額',
        qbye: 'ウォレット残高',
        finished: '終了',
        doing: '処理...',
        sl: '量',
        shouyilv: '利益率',
        ks: '開始時間',
        js: '終了時間'
    },
    login: {
        title: 'ログイン',
        lang: '言語',
        Account: 'アカウント',
        Password: 'パスワード',
        btn: 'ログイン',
        placeholder1: 'パスワードを入力してください',
        placeholder: 'アカウントを入力してください',
        fail: 'フォームに記入してください',
        ForgotPassword: 'パスワードをお忘れですか',
        NoAccount: 'まだアカウントを持っていませんか?',
        Registration: '登録',
        server: 'オンラインサービス',
        jzmm: 'パスワードを覚える',
        success: 'ログイン成功',
        Home: '表紙'
    },
    moneyAddr: {
        title: 'ウォレットアドレス',
        Currency: '通貨',
        Address: '住所',
        placeholder: '選んでください',
        DepositAddress: '入金先',
        WalletAddress: 'ウォレットアドレス',
        Date: '時間',
        add: 'ウォレットアドレスを追加',
        ChainAddress: 'チェーンアドレス',
        qrcode: 'ウォレットの QR コード',
        submit: '確認',
        tips: 'ウォレットの種類を選択してください',
        tips1: 'チェーン アドレス タイプを選択してください',
        tips2: 'ウォレットアドレスを入力してください',
        tips3: 'ウォレットの QR コードをアップロードしてください',
        success: '成功'
    },
    recharge: {
        title: '再充電',
        title1: 'リチャージの詳細',
        ls: 'リチャージの詳細',
        td: '次の充電チャネルを選択してください',
        bc: 'QRコードを保存',
        address: '入金先',
        copy: 'アドレスをコピー',
        num: '入金額',
        Upload: '支払い情報のスクリーンショットをアップロード',
        Submit: '確認',
        tip: '数量を入力してください',
        tip1: 'スクリーンショットをアップロードしてください',
        copySuc: '正常にコピー'
    },
    newChange: {
        num: '数量を入力してください',
        psd: 'パスワードを入力してください',
        hq: '市場',
        bb: 'ヘルプセンター',
        sec: '検証済み',
        ass: '資産',
        pool: '財務管理',
        newc: '私たちに関しては',
        rechange: 'リチャージ',
        with: '顧客サービス',
        a1: '金融',
        a2: 'Fnds in custody(USDT)',
        a3: '今日の利益',
        a4: '累計収益',
        a5: '進行中',
        a6: '注文',
        a7: 'ルール',
        a8: 'デイリーリターン',
        a9: 'シングルリミット',
        a10: '「サイクル」',
        a11: 'Pecently(日次収益)',
        a12: 'シングルリミット',
        a13: '預かり金額：',
        a14: '「申し込む」',
        a15: '収益の計算',
        a16: 'ブロックはUSDTをプラットフォームにホストすることによって移動され、プラットフォームはプロのチームがブロックを移動するアービトラージを実行します...',
        a17: '1 人のメンバーがプラットフォームに 10,000U を入金し、期間が 5 日間で、1 日あたりの収益率が 0.3% から 0.5% のウェルス マネジメント商品を選択しました。1 日の最高値: 10000U x 0.5% = 50 U n ということです。 150U-250U 収入は 5 日後に取得でき、収入は毎日発行され、発行された収入はいつでもアクセスできます。 預け入れた元本は失効しています。その後は・・・',
        a18: '約定損害金について',
        a19: '満了前の元本を譲渡する場合、約定損害金が発生します。約定損害金＝延滞率×残存日数×投資額。 N例：商品のデフォルト決済率は0.4%、残り3日以内に支払い期限、投資額は1000、その後の約定損害金=0.4%*3...',
        a20: '参加したい',
        help: 'ヘルプセンター'
    },
    msgbank: {
        msgTitle: 'メッセージ',
        noticeTitle: '公告',
        msgContent: '未読メッセージがあります',
        msgBtn: '見に行く',
        bankCard: '銀行カード',
        inputTips: '完全な情報を入力してください',
        submitSuccess: 'コミット成功',
        CardUserName: '口座開設者名',
        BankName: '口座銀行',
        OpenBank: '口座開設サイト',
        CardNo: '銀行カード番号',
        noBindBankmsg: '銀行カードを縛っていません',
        noBindBankBtn: 'バインド解除',
        modify: '変更',
    }
}