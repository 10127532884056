module.exports = {
    pcregister: {
        p1: '已有账号?',
        dl: '登录',
        zc: '注册',
        p4: '账户',
        p5: '请输入账号',
        p6: '请输入密码',
        p7: '请输入确认密码',
        p8: '请输入邀请码',
        p9: '我同意',
        p10: '《用户协议和隐私政策》',
        p11: '邮箱',
        p12: '请输入邮箱',
        p13: '请输入邮箱验证码',
        p14: '发送',
        p15: '年',
        p16: '月',
        p17: '天',
        p18: '小时',
        p19: '分钟',
        p20: '秒'
    },
    pclogin: {
        l1: '没有账号?',
        l2: '注册',
        l3: '登录',
        l4: '请输入用户名',
        l5: '请输入密码',
        l6: '忘记密码',
        l7: '登录'
    },
    pccontract: {
        ss: '市价',
        xj: '限价',
        gg: '杠杆'
    },
    pcrapidly: {
        zf: '涨幅',
        dd: '订单',
        bdsj: '本地时间',
        ky: '可用'
    },
    pchome: {
        global1: '全球领先的数字资产交易平台',
        global2: '它为100多个国家的数百万用户提供安全、可信的数字资产交易和资产管理服务。',
        global3: '安全可靠',
        global4: '五年数字资产金融服务经验。 专注于分布式架构和抗DDoS攻击系统。',
        global5: '全球生态布局',
        global6: '已在多个国家设立本地化交易服务中心，打造融合多种业态的区块链生态系统。',
        global7: '用户至上',
        global8: '建立先行补偿机制，设立投资者保护基金。',
        whever1: '随时随地多平台终端交易',
        whever2: '覆盖iOS、Android、Windows多个平台，支持全业务功能。',
        register1: '注册一个',
        register3: '全球账户开始交易',
        register2: '立即注册'
    },
    pcbottom: {
        box1: '全球领先',
        box2: '最好的团队之一',
        box3: '更多',
        box4: '关于我们',
        box5: '白皮书',
        box6: '服务',
        box7: '下载',
        box8: '常见问题',
        box9: '在线客服',
        box10: '规定',
        box11: '用户协议',
        box12: '隐私政策',
        box13: '法律声明',
        box14: '联系'
    },
    pcAbout_us: {
        t1: '历程',
        t2: '我们，7年之路',
        t3: '从2015年诞生以来，我们就心无旁骛的埋头为区块链服务，专职技术、通道搭建服务。',
        t4: '2014-2017年经历了“稳中有升”的上行期，2017-2018上半年是“买钱致富”的疯狂期，从2019年下半年到今天的寒冬期，我练就了一身真本事。无数成功的伙伴，让低调的我们在业界拥有高调的声誉。2022年，我们将拥有全新的平台V3.0，并成立新的股份制公司BitGoldEX，在全球100多个国家开展业务。',
        t5: '我们在找谁',
        t6: '从成立到今天',
        t7: '一直在寻找有困难，并与自己气味相投的服务商，几年时间成功地为几十个服务商解决问题. ',
        t8: '在理性的今天，如果你希望找一个好帮手，能够携手实现自己继续前进的伙伴——或许，可以找我们聊聊。',
        t9: '请相信：你在找的人，其实也一直在找你。',
        t10: '服务',
        t11: '没有业务员，一样有业务',
        t12: '朋友为你介绍生意，说明你为人还行。同行为你介绍生意，说明你口碑还不错。当客户为你介绍生意，那证明你是值得信赖的。',
        t13: '精神',
        t14: '我们是块砖，哪有需要往哪端',
        t15: '不同的客户，要求不一样。不同的产品，特性不一样。不同的时机，目标不一样。我们存在的意义，就是去适应不同服务商的理念、制度和要求，解决各种各样的问题。',
        t16: '我们愿意做一块有思想、有作用的砖，哪里有需要，我们就往哪端。'
    },
    pcproblem: {
        p1: '常见问题',
        p2: '为什么转换后的资产数量会发生变化？',
        p3: '资产中的等值计算是当前持有的数字货币换算成USDT的价值，因数字资产的价格波动而变化。您的数字资产数量没有改变。',
        p4: '平台交易忘记资金密码处理方法',
        p5: '如忘记平台交易资金密码，请前往“我的-设置-点击设置资金密码”或联系客服重置',
        p6: '交易购买后资金流向哪里？',
        p7: '采购订单完成后，资金将进入资产中的法币账户。如需购买资产进行合约交易或期货交易，请到资金划转模块进行划转。',
        p8: '为什么要限制昵称的更改次数',
        p9: '出于您交易的安全考虑，我们目前限制昵称只能修改一次。',
        p10: '转移的时候是什么意思？为什么需要转移',
        p11: '为保证您账户之间资金的独立性，便于您合理控制风险，我们特此划分各主要交易模块的账户。转账是指交易账户之间进行资产转换的过程。',
        p12: '用户合约的账户权益如何计算？',
        p13: '合约账户净值=头寸保证金+头寸浮动盈亏+活期账户可用金额',
        p14: '开仓时止损和止盈设置的值是多少？应该怎么设置？',
        p15: '以盈亏为您设定的盈亏上限。当达到设定的止盈止损金额时，系统会自动平仓。可用于购买合约时的风险控制。设置止盈量的一半为：增加量x数量x杠杆倍数，设置止损。我们建议您根据自己的实际资产情况进行设置，合理控制风险。',
        p16: '如何降低合同风险？',
        p17: '您可以通过划转资金的方式将剩余账户的可用资产转入合约账户，或者通过减少持仓来降低风险',
        p18: '合约开仓的数量是什么意思？',
        p19: '未平仓头寸的数量代表您期望购买的货币数量。例如：在BTC/USDT交易对的开盘页面选择，做多，输入价格为1000USDT，输入数量为10BTC，即：您预计买入10个BTC，单价为1000USDT。',
        p20: '合同中的手续费是如何计算的？',
        p21: '手续费=开仓价格*开仓数量*手续费率',
        p22: '强制平仓注意事项',
        p23: '风险程度是衡量您资产风险的指标。当风险度等于100%时，您的仓位被视为平仓，风险度=（持有保证金/合约账户权益）*100%，为防止用户穿仓，系统设置调整风险度比。当风险程度达到系统设定的风险值时，系统将强制平仓。例如：系统设置的调整比例为10%，那么当您的风险度大于等于90%时，您的所有仓位将被系统强制平仓。',
        p24: '合约交易规则是什么？',
        p25: '交易类型',
        p26: '交易类型分为多头（买入）和空头（卖出）两个方向：',
        p27: '买入多头（看涨）表示您认为当前指数有可能上涨，并且您想买入一定数量的一定数量的以您设定的价格或当前市场价格签订合约。',
        p28: '卖空（看跌）是指您认为当前指数可能下跌，并希望以您设定的价格或当前市场价格卖出一定数量的新合约。',
        p29: '下单方式',
        p30: '限价下单：您需要指定下单的价格和数量',
        p31: '市价单：您只需设置下单数量，价格为当前市价',
        p32: '持仓',
        p33: '当您提交的开仓指令完成后，称为持仓',
        p34: '合约交割问题',
        p35: '平台合约为永续合约，不设交货时间。只要系统不满足强平条件或者您没有手动平仓，您就可以永久持仓。系统平仓',
        p36: '1：达到止盈止损设定值，系统自动平仓',
        p37: '2：风险过高，系统强制平仓',
        p38: '合约交易的保证金是多少？',
        p39: '在合约交易中，用户可以根据合约价格和数量，以及对应的杠杆倍数参与合约的买卖。用户开仓时会占用保证金。开仓保证金越多，账户风险就越高。',
        p40: '什么是限价单和市价单？',
        p41: '限价单是指您期望以什么价格委托平台进行交易，市价单是指直接委托平台以当前价格进行交易。在开仓规则中，市价单优先于限价单。如果您选择限价单，请按照币种当前市场价格合理开仓，避免因开仓价格不合理造成损失。',
        p42: '合约交易的风险等级代表什么？',
        p43: '风险度是您合约账户中的风险指标。风险程度等于 100% 被视为清算。我们建议当您的风险超过50%时，您需要谨慎开仓，避免因平仓而造成损失。您可以通过补充合约资产的可用资金或减仓来降低风险。',
        p44: '为什么需要货币兑换？',
        p45: '交易所的目的是让您资产中不同币种的资金顺利流通，期货账户中获得的QCC可以自由兑换成USDT进行交易。其他账户中的USDT也可以自由兑换成QCC进行交易。',
        p46: '为什么转账后对方没有及时收到转账？',
        p47: '首先，请确保您在转账时填写的支付方式与订单详情页面显示的支付方式完全相同。然后请确认您的付款方式是立即付款还是延迟付款。最后，请联系您的银行和支付机构进行系统维护或其他原因。',
        p48: '如何通过平台购买USDT？',
        p49: '方法一：通过平台买卖清单选择您要买入的订单进行买卖。',
        p50: '方法二：点击发布按钮，根据您的需要发布买单或卖单进行快速交易。该系统将快速为您匹配买卖用户。',
        p51: '注意：如果采购订单15分钟后仍未付款，系统会自动取消订单，请及时付款。当天取消订单超过3次，当天不能再次交易，次日恢复交易权限。'
    },
    public: {
        zxkf: '在线客服',
        sure: '确认',
        cancel: '取消',
        RefreshSucceeded: '刷新成功',
        noMoreData: '没有更多数据了',
        pullingText: '下拉即可刷新 ...',
        ReleaseText: '释放即可刷新 ...',
        loadingText: '加载中 ...',
        noData: '没有记录',
        xq: '详情',
        submit: '确认',
        kefu: '在线客服'
    },
    ChangeType: {
        Upper: '系统充值',
        Lower: '系统提现',
        Win: '盈利',
        Bet: '投资',
        BuyMining: '挖矿',
        CoinWin: '新币盈利',
        SettleMining: '挖矿本金返回',
        GiveInterest: '挖矿收益',
        BuyCoin: '新币未中签',
        BuyCoinBack: '新币申购返回',
        UsdtToBtc: 'Usdt转Btc',
        UsdtToEth: 'Usdt转Eth',
        BtcToUsdt: 'Btc转Usdt',
        BtcToEth: 'Btc转Eth',
        EthToUsdt: 'Eth转Usdt',
        EthToBtc: 'Eth转Btc',
        CoinFrozen: '新币中签冻结',
        CoinThaw: '新币解冻',
        CoinCancel: '币币订单撤销',
        CoinBuy: '币币买入',
        CoinBuyEntrust: '币币买入委托',
        CoinSellEntrust: '币币卖出委托',
        CoinSell: '币币卖出',
        ContractBuy: '合约下单，扣除保证金',
        ContractCharge: '合约下单，扣除手续费',
        ContractCancelToBond: '合约撤销退还保证金',
        ContractCancelToCharge: '合约撤销退还手续费',
        ContractCloseProfit: '合约平仓收益'
    },
    trade: {
        title: '币币',
        Buy: '买入',
        Sell: '卖出',
        pc: '平仓',
        cb: '成本',
        xj1: '现价',
        yk: '盈亏',
        xianjia: '限价',
        jiage: '价格',
        shuliang: '数量',
        keyong: '可用',
        yonghuchicang: '用户持仓',
        fxlts: '风险率=持仓保证金/客户权益再乘以100%，其中客户权益等于账户可用资金+初始交易保证金+浮动盈亏',
        fxl: '风险率',
        dqwt: '当前委托',
        all: '全部',
        name: '名称',
        shijian: '时间',
        zyjg: '以当前最优价格交易',
        caozuo: '操作',
        xj: '限价',
        cc: '持仓',
        leixing: '类型',
        long: '做多',
        short: '做空',
        sj: '市价',
        jiaoyie: '交易额',
        orderNo: '订单号',
        weituojia: '委托价',
        chengjiaojia: '成交价',
        chengjiaoe: '成交额',
        weituo: '委托方式',
        qbwt: '全部委托',
        lswt: '历史委托',
        tips: '请输入正确的价格',
        tips1: '请输入数量',
        Create: '委托中',
        Wait: '交易中',
        Complete: '已平仓',
        Cancel: '已撤单',
        zsj: '止损价',
        zyj: '止盈价',
        cczsy: '持仓总收益',
        ykbl: '盈亏比例',
        bili: '比例',
        jyss: '交易手数',
        bzj: '保证金',
        sxf: '手续费',
        gg: '杠杆倍数',
        yjpc: '一键平仓'
    },
    auth: {
        title: '身份认证',
        title1: '身份认证',
        low: '初级认证',
        high: '高级认证',
        success: '认证成功',
        tips: '请先进行初级认证',
        tips1: '请输入真实姓名',
        tips2: '请输入身份证号码',
        tips3: '请上传身份证正面照片',
        tips4: '请上传身份证背面照片',
        tips5: '请上传手持照片',
        qrz: '去认证',
        shz: '审核中',
        rzsb: '认证失败',
        userName: '真实姓名',
        IDNumber: '身份证号',
        zmz: '身份证正面照片',
        bmz: '身份证背面照片',
        scz: '手持照'
    },
    share: {
        title: '我的推广码',
        cp: '复制地址',
        success: '复制成功'
    },
    property: {
        Contract: '币币',
        Exchange: '秒合约',
        zzc: '总资产折合',
        mhyzh: '秒合约账户',
        bbzh: '币币账户',
        available: '可用',
        OnOrders: '冻结',
        Estimated: '折合',
        cw: '财务记录',
        number: '数量',
        record: '记录',
        Transfer: '划转',
        time: '时间',
        dhsl: '兑换数量',
        tips: '请输入兑换数量',
        qbdh: '全部兑换',
        dhhl: '兑换汇率',
        ky: '可用',
        kd: '可得',
        sxfbl: '手续费比例',
        sxf: '手续费'
    },
    statusLang: {
        Review: '审核',
        Success: '成功',
        Fail: '失败'
    },
    with: {
        title: '提现',
        history: '提现记录',
        ldz: '链地址',
        tbdz: '提现地址',
        tips: '请输入提现地址',
        tips1: '请输入提现数量',
        tips2: '请输入支付密码',
        tbsl: '提现数量',
        ky: '可用',
        zjmm: '支付密码',
        sxf: '手续费',
        dz: '预计到账数量'
    },
    tabBar: {
        home: '首页',
        Quotation: '行情',
        Quotation2: '市场行情',
        Trade: '币币',
        Contract: '秒合约',
        assets: '资产',
        qhyy: '切换语言',
        zyjb: '锁仓挖矿',
        jsjy: '极速交易',
        jryw: '金融业务',
        hyjy: '合约交易',
        bbjy: '币币交易'
    },
    home: {
        UserName: '账户',
        kjmb: '快捷买币',
        score: '信用分',
        zc: '支持BTC、USDT等',
        scwk: '锁仓挖矿',
        sgxb: '新币申购',
        aqkx: '安全可信',
        aqkx1: '全球领先的数字资产交易平台',
        zfb: '涨幅榜',
        dfb: '跌幅榜',
        mc: '名称',
        hy: '合约',
        zxj: '最新价',
        zdb: '涨跌幅',
        sfyz: '身份认证',
        skfs: '收款方式',
        share: '我的分享链接',
        kjcb: '快捷充值',
        kjtb: '快捷提现',
        qbdz: '钱包地址',
        bps: '白皮书',
        xgmm: '修改密码',
        xgzfmm: '修改支付密码',
        gywm: '关于我们',
        tcdl: '退出'
    },
    quotation: {
        title: '行情',
        mc: '数量',
        zxj: '最新价',
        zdb: '涨跌幅',
        liang: '量'
    },
    contract: {
        kcslfw: '开仓数量范围：',
        gao: '高',
        di: '低',
        liang: '24H量',
        placeholder: '请输入数量',
        SuccessfulPurchase: '购买成功',
        smallMin: '分钟',
        hour: '小时',
        dayMin: '天',
        Trend: '分时',
        ccjl: '持仓列表',
        ddjl: '订单记录',
        sl: '数量',
        gmj: '购买价',
        dqj: '当前价',
        yjyk: '预计盈亏',
        djs: '倒计时',
        gbjg: '成交价',
        sc: '手续费',
        pal: '盈亏',
        mhy: '秒合约',
        sj: '时间',
        yll: '盈利率',
        mz: '买涨',
        md: '买跌',
        kcsl: '开仓数量',
        kcsj: '开仓时间',
        zhye: '账户余额',
        qc: '取消'
    },
    editPassword: {
        title: '修改密码',
        jmm: '旧密码',
        xmm: '新密码',
        qrmm: '确认密码',
        placeholder: '旧密码',
        placeholder1: '设置新密码',
        placeholder2: '请重新输入新密码',
        qrxf: '确认'
    },
    withPass: {
        title: '取款密码',
        jmm: '旧密码',
        xmm: '新密码',
        qrmm: '确认密码',
        placeholder: '旧密码',
        placeholder1: '设置新密码',
        placeholder2: '请重新输入新密码',
        qrxf: '确认',
        tips: '请输入至少6位的旧密码',
        tips1: '请输入至少6位的新密码',
        tips2: '请输入正确的确认密码',
        tips3: '两次输入的新密码不一致',
        tips4: '设置密码成功',
        confirm: '确认'
    },
    newBe: {
        title: '新币申购',
        title1: '我的申购',
        scqx: '锁仓期限',
        day: '天',
        sy: '剩余',
        sdsg: '记录',
        sysl: '剩余数量',
        gmsl: '购买数量',
        dj: '单价',
        tips: '请输入购买数量',
        gmzj: '购买总价',
        qbye: '钱包余额',
        fmzj: '购买总价',
        gm: '购买',
        sgsj: '申购时间',
        Currency: '币种',
        Request: '申请数量',
        Passing: '通过数量',
        MarketTime: '上市时间',
        sqz: '申请中'
    },
    register: {
        title: '注册',
        lang: '语言',
        send: '发送',
        Resend: '重新发送',
        Account: '账户',
        Password: '密码',
        ConfirmPassword: '确认密码',
        InvitationCode: '邀请码',
        btn: '注册',
        amount: '账号',
        mailbox: '邮箱',
        haveAccount: '是否已有账号？',
        Login: '登录',
        placeholder: '请输入邮箱',
        placeholder0: '请输入账号',
        placeholder1: '请输入密码',
        placeholder2: '请输入确认密码',
        placeholder3: '请输入邀请码',
        placeholder4: '请输入邮箱验证码',
        placeholder5: '请输入正确的邮箱',
        success: '注册成功',
        message: '密码至少6位',
        message1: '确认密码至少6位',
        message2: '两次密码不匹配',
        message3: '请阅读并勾选协议',
        agree: '我同意',
        xy: '用户协议和隐私政策',
        qr: '确认'
    },
    lockming: {
        title: '锁仓列表',
        wkb: '挖矿宝',
        wkgjd: '挖矿更简单',
        qsljl: '轻松领奖励',
        yjzsr: '预计总收益',
        ck: '近3日参考年化',
        tips: '至少 ',
        rlx: '日利息',
        day: '天',
        scqx: '锁仓期限',
        buy: '购买',
        zysl: '质押数量',
        qbye: '钱包余额',
        finished: '完成',
        doing: '进行中...',
        sl: '数量',
        shouyilv: '收益率',
        ks: '开始时间',
        js: '结束时间'
    },
    login: {
        title: '登录',
        lang: '语言',
        Account: '账户',
        Password: '密码',
        btn: '登录',
        placeholder1: '请输入密码',
        placeholder: '请输入账号',
        fail: '请填写完整的表格',
        ForgotPassword: '忘记密码',
        NoAccount: '还没有账号？',
        Registration: '注册',
        server: '在线客服',
        jzmm: '记住密码',
        success: '登录成功',
        Home: '首页'
    },
    moneyAddr: {
        title: '钱包地址',
        Currency: '币种',
        Address: '地址',
        placeholder: '请选择',
        DepositAddress: '充值地址',
        WalletAddress: '钱包地址',
        Date: '时间',
        add: '添加钱包地址',
        ChainAddress: '链地址',
        qrcode: '钱包二维码',
        submit: '确认',
        tips: '请选择钱包类型',
        tips1: '请选择链地址类型',
        tips2: '请输入钱包地址',
        tips3: '请上传钱包二维码',
        success: '成功'
    },
    recharge: {
        title: '充值',
        title1: '充值明细',
        ls: '充值明细',
        td: '请选择以下充值通道',
        bc: '保存二维码',
        address: '充值地址',
        copy: '复制地址',
        num: '充值数量',
        Upload: '上传支付详情截图',
        Submit: '确认',
        tip: '请输入数量',
        tip1: '请上传截图',
        copySuc: '复制成功'
    },
    newChange: {
        num: '请输入数量',
        psd: '请输入密码',
        hq: '行情',
        bb: '帮助中心',
        sec: '实名认证',
        ass: '资产',
        pool: '理财',
        newc: '关于我们',
        rechange: '充值',
        with: '客服',
        help: '帮助中心',
        a1: '财务',
        a2: '在押资金(USDT)',
        a3: '今日利润',
        a4: '累计收益',
        a5: '进行中',
        a6: '订单',
        a7: '规则',
        a8: '每日回报',
        a9: '单次限制',
        a10: '循环',
        a11: 'Pecently(每日收入)',
        a12: '单个限制',
        a13: '托管数量：',
        a14: '订阅',
        a15: '收益计算',
        a16: '搬砖是通过托管USDT到平台，平台有专业团队进行搬砖套利...',
        a17: '某会员在平台入金10000U，选择了一款期限为5天，日收益率为0.3%至0.5%的理财产品。每日最高：10000U x 0.5% = 50 U n 即 5天后可获得150U-250U的收益，收益每日发放，发放的收益可随时取用。 存入的本金已过期，之后将是...',
        a18: '关于违约金',
        a19: '如果您要转让未到期本金，将产生违约金。违约金=违约清算比例*剩余天数*投资次数。 N例：产品默认结算比例为0.4%，剩余3天到期，投资金额为1000，则违约金=0.4%*3...',
        a20: '我要加入'
    },
    msgbank: {
        msgTitle: '消息',
        noticeTitle: '公告',
        msgContent: '您有未读的消息',
        msgBtn: '去查看',
        bankCard: '银行卡',
        inputTips: '请输入完整信息',
        submitSuccess: '提交成功',
        CardUserName: '开户人姓名',
        BankName: '开户银行',
        OpenBank: '开户网点',
        CardNo: '银行卡号',
        noBindBankmsg: '您还没有绑定银行卡',
        noBindBankBtn: '去绑定',
        modify: '修改'
    }
}